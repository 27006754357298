// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__AyGna {
    background-color: rgba(251,248,239,1);
    color: black;
    text-align: center;
    padding: 10px 0;
  }

  .styles_qrMenu__k92Kk {
    display: inline-block;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif; font-weight: bold;
  }

  nav {
    background-color: #f4f4f4;
    padding: 10px 0;
    text-align: center;
  }

  nav a {
    display: inline-block;
    margin: 0 10px;
    color: #333;
    text-decoration: none;
    font-family: 'Poppins', sans-serif; font-weight: medium; color: rgba(164,129,53,1);
    font-weight: bold;
  }

  nav a:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/layouts/Navbar/styles.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,kCAAkC,EAAE,iBAAiB;EACvD;;EAEA;IACE,yBAAyB;IACzB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,cAAc;IACd,WAAW;IACX,qBAAqB;IACrB,kCAAkC,EAAE,mBAAmB,EAAE,yBAAyB;IAClF,iBAAiB;EACnB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".header {\r\n    background-color: rgba(251,248,239,1);\r\n    color: black;\r\n    text-align: center;\r\n    padding: 10px 0;\r\n  }\r\n\r\n  .qrMenu {\r\n    display: inline-block;\r\n    margin-right: 10px;\r\n    font-family: 'Poppins', sans-serif; font-weight: bold;\r\n  }\r\n\r\n  nav {\r\n    background-color: #f4f4f4;\r\n    padding: 10px 0;\r\n    text-align: center;\r\n  }\r\n\r\n  nav a {\r\n    display: inline-block;\r\n    margin: 0 10px;\r\n    color: #333;\r\n    text-decoration: none;\r\n    font-family: 'Poppins', sans-serif; font-weight: medium; color: rgba(164,129,53,1);\r\n    font-weight: bold;\r\n  }\r\n\r\n  nav a:hover {\r\n    text-decoration: underline;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__AyGna`,
	"qrMenu": `styles_qrMenu__k92Kk`
};
export default ___CSS_LOADER_EXPORT___;
