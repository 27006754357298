import "./App.css";
import PageManager from "./features/PageManager";

function App() {
  console.log("App.js");
  return (
    <div className="App">
      <PageManager />

    </div>
  );
}

export default App;
