// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_card__3H9ED {
  justify-items: center;
  position: relative;
  display: inline-block;
  align-self: center;
  /* width: 173px;
  height: 187px; */
  margin: 1rem;
  /* padding: 0.3rem; */
  border: 1px solid #d9b99b;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: white;
}

.styles_cardImage__WpAqV {
  display: flex;
  /* width: 173px;
  height: 95px; */
  justify-content: center;
}

.styles_cardImage__WpAqV img {
  border-radius: 1rem 1rem 0 0; 
  width: 100%;
  height: 100%;
  /* object-fit: inherit;  */
}

.styles_info__ot5Uu {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.styles_title__gDY2z {
  display: flex;
  font-family: 'Times New Roman', Times, serif;
  margin: 0.5rem 0 ;
  padding: 0;
  font-size: medium;
  height: 2rem;
}

.styles_price__\\+6Aty {
  display: flex;
  color: #a36446;
  margin: 0;
  padding: 0;
  font-weight:bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Urun/styles.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB;kBACgB;EAChB,YAAY;EACZ,qBAAqB;EACrB,yBAAyB;EACzB,mBAAmB;EACnB,uCAAuC;EACvC,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb;iBACe;EACf,uBAAuB;AACzB;;AAEA;EACE,4BAA4B;EAC5B,WAAW;EACX,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,4CAA4C;EAC5C,iBAAiB;EACjB,UAAU;EACV,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;EACd,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".card {\r\n  justify-items: center;\r\n  position: relative;\r\n  display: inline-block;\r\n  align-self: center;\r\n  /* width: 173px;\r\n  height: 187px; */\r\n  margin: 1rem;\r\n  /* padding: 0.3rem; */\r\n  border: 1px solid #d9b99b;\r\n  border-radius: 1rem;\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  transition: all 0.3s ease;\r\n  background-color: white;\r\n}\r\n\r\n.cardImage {\r\n  display: flex;\r\n  /* width: 173px;\r\n  height: 95px; */\r\n  justify-content: center;\r\n}\r\n\r\n.cardImage img {\r\n  border-radius: 1rem 1rem 0 0; \r\n  width: 100%;\r\n  height: 100%;\r\n  /* object-fit: inherit;  */\r\n}\r\n\r\n.info {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin: 0.5rem;\r\n}\r\n\r\n.title {\r\n  display: flex;\r\n  font-family: 'Times New Roman', Times, serif;\r\n  margin: 0.5rem 0 ;\r\n  padding: 0;\r\n  font-size: medium;\r\n  height: 2rem;\r\n}\r\n\r\n.price {\r\n  display: flex;\r\n  color: #a36446;\r\n  margin: 0;\r\n  padding: 0;\r\n  font-weight:bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `styles_card__3H9ED`,
	"cardImage": `styles_cardImage__WpAqV`,
	"info": `styles_info__ot5Uu`,
	"title": `styles_title__gDY2z`,
	"price": `styles_price__+6Aty`
};
export default ___CSS_LOADER_EXPORT___;
