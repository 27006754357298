// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*add simple css for this page*/
.styles_container__x06tQ {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .styles_kunefeSection__GE3\\+9 {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .styles_baklavaSection__CqtKb {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .styles_kadayifSection__cZv4y {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .styles_iceceklerSection__PGm8x {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}`, "",{"version":3,"sources":["webpack://./src/page/QrMenu/styles.module.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI;QACI,aAAa;QACb,8BAA8B;IAClC;;IAEA;QACI,aAAa;QACb,8BAA8B;IAClC;;IAEA;QACI,aAAa;QACb,8BAA8B;IAClC;;IAEA;QACI,aAAa;QACb,8BAA8B;IAClC;AACJ","sourcesContent":["/*add simple css for this page*/\r\n.container {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n    .kunefeSection {\r\n        display: grid;\r\n        grid-template-columns: 1fr 1fr;\r\n    }\r\n\r\n    .baklavaSection {\r\n        display: grid;\r\n        grid-template-columns: 1fr 1fr;\r\n    }\r\n\r\n    .kadayifSection {\r\n        display: grid;\r\n        grid-template-columns: 1fr 1fr;\r\n    }\r\n\r\n    .iceceklerSection {\r\n        display: grid;\r\n        grid-template-columns: 1fr 1fr;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__x06tQ`,
	"kunefeSection": `styles_kunefeSection__GE3+9`,
	"baklavaSection": `styles_baklavaSection__CqtKb`,
	"kadayifSection": `styles_kadayifSection__cZv4y`,
	"iceceklerSection": `styles_iceceklerSection__PGm8x`
};
export default ___CSS_LOADER_EXPORT___;
